import dynamic from 'next/dynamic';

const BannerImage = dynamic(() => import('components/Banner/BannerImage'));
const BannerVideo = dynamic(() => import('components/Banner/BannerVideo'));

const Banner = ({ settings }: any) => {
    const bannerType = settings.bannerType;

    switch (bannerType) {
    case 'video':
        return <BannerVideo settings={settings} />;
    case 'slider-images':
        return <BannerImage settings={settings} />;
    default:
        return <></>;
    }
};

export default Banner;